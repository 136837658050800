<template>
  <div class="DataAnalyse">
    <!-- 数据总览 -->
    <div class="box box1">
      <div class="box_title">
        <img class="title_icon" src="@/assets/image/dataAnalyse/title_icon1.png" alt="数据总览">
        <span class="title_txt">数据总览</span>
      </div>
      <div class="box_term">
        <div class="term_item">今日</div>
        <div class="term_item pick">昨日</div>
        <div class="term_item">7天内</div>
        <div class="term_item">30天内</div>
      </div>
      <div class="box_con">
        <div 
          class="con_item" 
          :class="{'item1': index==0, 'item2': index==1,'item3': index==2,'item4': index==3}"
          v-for="(item, index) in 4" 
          :key="index">
          <div class="item_top">
            <img class="top_icon" src="@/assets/image/dataAnalyse/box1_icon1.png" alt="订单数">
            <span class="top_txt">订单数</span>
          </div>
          <div class="item_bottom">
            <div class="bottom_num">
              <span class="num_txt">92425</span>
              <span class="num_info">笔</span>
            </div>
            <div class="bottom_up">
              <span class="up_info">比昨日：＋</span>
              <span class="up_con">23</span>
              <img class="up_icon" v-if="true" src="@/assets/image/dataAnalyse/box1_icon5.png" alt="上升">
              <img class="up_icon" v-else src="@/assets/image/dataAnalyse/box1_icon6.png" alt="下降">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 用户数据 -->
    <div class="box box2">
      <div class="box_title">
        <img class="title_icon" src="@/assets/image/dataAnalyse/title_icon2.png" alt="用户数据">
        <span class="title_txt">用户数据</span>
      </div>
      <div class="box_input">
        <a-range-picker @change="onChange1" />
      </div>
      <div class="box_echart box_echart1">
        <div class="echart_item">
          <div class="box_info_title">
            <span class="line"></span>
            <span class="title_txt">用户认证</span>
            <span class="title_info">(人数)</span>
          </div>
          <div class="echart_main">
            <div id="echarts1" style="width: 90%; height: 520px"></div>
          </div>
        </div>
        <div class="echart_item">
          <div class="box_info_title">
            <span class="line"></span>
            <span class="title_txt">注册情况</span>
            <span class="title_info">(人数)</span>
          </div>
          <div class="echart_main">
            <div id="echarts2" style="width: 90%; height: 520px"></div>
          </div>
        </div>
      </div>
      <div class="box_line"></div>
      <div class="box_echart">
        <div class="echart_item">
          <div class="box_info_title">
            <span class="line"></span>
            <span class="title_txt">不同时间段数据统计</span>
            <span class="title_info">(人数)</span>
          </div>
          <div class="echart_main">
            <div id="echarts3" style="width: 90%; height: 520px"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 订单数据 -->
    <div class="box box3">
      <div class="box_title">
        <img class="title_icon" src="@/assets/image/dataAnalyse/title_icon3.png" alt="用户数据">
        <span class="title_txt">订单数据</span>
      </div>
      <div class="box_input">
        <a-range-picker @change="onChange2" />
      </div>
      <div class="box_echart">
        <div class="echart_item echart_item1">
          <div class="box_info_title">
            <span class="line"></span>
            <span class="title_txt">订单数据</span>
          </div>
          <div class="echart_main">
            <!-- 订单数据 -->
            <div id="echarts4" style="width: 90%; height: 520px"></div>
          </div>
        </div>
        <div class="echart_item echart_item2">
          <div class="box_info_title">
            <span class="line"></span>
            <span class="title_txt">商品销量排行</span>
          </div>
          <div class="box_con">
            
            <div class="con_swiper">
              <swiper
                ref="mySwiper"
                :options="swiperOptions"
              >
                <swiper-slide
                  class="swiper_slide"
                  v-for="(item, index) in mailArr"
                  :key="index"
                >
                  <div class="slide_wrap">
                    <div class="con_title">
                      <div class="title_txt title1">排名</div>
                      <div class="title_txt title2">商品名称</div>
                      <div class="title_txt title3">销量</div>
                    </div>
                    <div 
                      class="swiper_item" 
                      v-for="(itemI, indexI) in item" 
                      :key="indexI">
                      <div class="item_index">{{indexI+1}}</div>
                      <div class="item_con">{{itemI.title}}</div>
                      <div class="item_num">{{itemI.num}}</div>
                    </div>
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 学习数据 -->
    <div class="box box4">
      <div class="box_title">
        <img class="title_icon" src="@/assets/image/dataAnalyse/title_icon4.png" alt="用户数据">
        <span class="title_txt">学习数据</span>
      </div>
      <div class="box_input">
        <a-select 
          placeholder="请选择课程"
          style="width: 205px; margin-right: 12px;" 
          @change="handleChange">
          <a-icon slot="suffixIcon" type="caret-down"/>
          <a-select-option value="jack">Jack</a-select-option>
          <a-select-option value="lucy">Lucy</a-select-option>
          <a-select-option value="Yiminghe">yiminghe</a-select-option>
        </a-select>
        <a-range-picker @change="onChange3" />
      </div>
      <div class="box_echart">
        <div class="echart_item">
          <div class="box_info_title">
            <span class="line"></span>
            <span class="title_txt">考试数据</span>
          </div>
          <div class="echart_main">
            <div class="main_label">
              <div class="label_title">考试情况</div>
              <div class="label_con">
                <div class="con_item">考试人数：32</div>
                <div class="con_item">合格人数：32</div>
                <div class="con_item">合格率：90%</div>
              </div>
            </div>
            <div id="echarts5" style="width: 90%; height: 520px"></div>
          </div>
        </div>
      </div>
      <div class="box_line"></div>
      <div class="box_echart">
        <div class="echart_item">
          <div class="box_info_title">
            <span class="line"></span>
            <span class="title_txt">买课以及考试情况</span>
          </div>
          <div class="box_con">
            <div class="con_item">
              <div class="item_title">考试人数</div>
              <div class="item_line">
                <div class="item_progress" style="width: 20%;"></div>
              </div>
              <div class="item_num">8059</div>
            </div>
            <div class="con_item">
              <div class="item_title">考试通过人数</div>
              <div class="item_line">
                <div class="item_progress" style="width: 50%;"></div>
              </div>
              <div class="item_num">8059</div>
            </div>
            <div class="con_item">
              <div class="item_title">报名人数</div>
              <div class="item_line">
                <div class="item_progress" style="width: 80%;"></div>
              </div>
              <div class="item_num">8059</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 用户认证
      echarts1Data: {
        legend: {
          left: "0%",
          bottom: "0%",
          itemGap: 70,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['2022-06-26', '2022-06-26', '2022-06-26', '2022-06-26', '2022-06-26', '2022-06-26', '2022-06-26']
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '家长',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            stack: 'total',
            itemStyle: {
              // 点的颜色。
              color: '#0EF083'
            },
            symbolSize: 0,
            // emphasis: {
            //   focus: 'series'
            // },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: 'rgba(14, 239, 131, 0.1700)' // 0% 处的颜色
                }, {
                    offset: 1, color: 'rgba(196, 196, 196, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            smooth: true,
          },
          {
            name: '从业者',
            data: [200, 555, 1110, 555, 752, 812, 125],
            type: 'line',
            itemStyle: {
              // 点的颜色。
              color: '#49A9EE'
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: 'rgba(186, 222, 248, 1)' // 0% 处的颜色
                }, {
                    offset: 1, color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            symbolSize: 0,
            smooth: true
          }
        ]
      },

      // 注册情况
      echarts2Data: {
        legend: {
          left: "0%",
          bottom: "0%",
          itemGap: 70,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Email',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            itemStyle: {
              // 点的颜色。
              color: '#0EF083'
            },
            symbolSize: 0,
            smooth: true
          },
          {
            name: 'Email1',
            data: [200, 555, 1110, 555, 752, 812, 125],
            type: 'line',
            itemStyle: {
              // 点的颜色。
              color: '#49A9EE'
            },
            symbolSize: 0,
            smooth: true
          }
        ]
      },

      // 不同时间段数据统计
      echarts3Data: {
        legend: {
          left: "0%",
          bottom: "0%",
          itemGap: 70,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            label: {
              formatter: '2016-07-12 {value}'
            },
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['0:00', '2:00', '4:00', '6:00', '8:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00', '24:00']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '浏览量（PV）',
            data: [820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290, 1330],
            type: 'line',
            itemStyle: {
              // 点的颜色。
              color: '#0EF083'
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: 'rgba(14, 239, 131, 0.1700)' // 0% 处的颜色
                }, {
                    offset: 1, color: 'rgba(196, 196, 196, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            symbolSize: 0,
            smooth: true
          },
          {
            name: '访客量（PU）',
            data: [200, 555, 1110, 555, 752, 812, 125, 200, 555, 1110, 555, 752, 812],
            type: 'line',
            itemStyle: {
              // 点的颜色。
              color: '#49A9EE'
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: 'rgba(186, 222, 248, 1)' // 0% 处的颜色
                }, {
                    offset: 1, color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            symbolSize: 0,
            smooth: true
          }
        ]
      },

      // 订单数据
      echarts4Data: {
        legend: {
          itemGap: 50,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '商品订单',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'bar',
            stack: 'total',
            itemStyle: {
              color: '#FAC858'
            },
            smooth: true
          },
          {
            name: '补考订单',
            data: [200, 555, 1110, 555, 752, 812, 125],
            type: 'bar',
            stack: 'total',
            itemStyle: {
              color: '#EE6666'
            },
            smooth: true
          },
          {
            name: '延期订单',
            data: [290, 555, 1110, 555, 752, 812, 125],
            type: 'bar',
            stack: 'total',
            itemStyle: {
              color: '#73C0DE'
            },
            smooth: true
          },
          {
            name: '电子订单',
            data: [400, 555, 1110, 555, 752, 812, 125],
            type: 'bar',
            stack: 'total',
            itemStyle: {
              color: '#91CC75'
            },
            smooth: true
          },
        ]
      },

      // 考试数据
      echarts5Data: {
        legend: {
          left: "1%",
          itemGap: 50,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: ['6月22号', '5月22号', '4月22号', '3月22号']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '60-50',
            data: [820, 932, 1330, 1320],
            type: 'bar',
            itemStyle: {
              color: '#FAC858'
            },
            smooth: true
          },
          {
            name: '49-42',
            data: [200, 555, 812, 125],
            type: 'bar',
            itemStyle: {
              color: '#EE6666'
            },
            smooth: true
          },
          {
            name: '42以下',
            data: [290, 555, 812, 125],
            type: 'bar',
            itemStyle: {
              color: '#73C0DE'
            },
            smooth: true
          }
        ]
      },

      mailArr: [
        [{
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },
        {
          title: '卡罗来纳特殊教育评估培训课程CCITSN',
          num: '100000'
        },
        {
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },
        {
          title: '卡罗来纳特殊教育评估培训课程CCITSN',
          num: '100000'
        },
        {
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },
        {
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },
        {
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },
        {
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },
        {
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },
        {
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        }],
        [{
          title: '孤独症康复教育上岗培训课程',
          num: '100000'
        },]
      ],
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
    };
  },
  // 事件处理器
  methods: {
    onChange1() { },
    onChange2() { },
    handleChange() { },
    onChange3() { },
    // 用户认证
    getUserIdentification() {
      // 用户认证
      var echarts1 = echarts.init(
        document.getElementById("echarts1"),
        null,
      );
      // 绘制图表
      echarts1.setOption(this.echarts1Data);
      window.addEventListener("resize", echarts1.resize);
    },

    // 注册情况
    getRegister() {
      var echarts2 = echarts.init(
        document.getElementById("echarts2"),
        null,
      );
      // 绘制图表
      echarts2.setOption(this.echarts2Data);
      window.addEventListener("resize", echarts2.resize);
    },

    // 不同时间段数据统计
    getPeopleNum() {
      var echarts3 = echarts.init(
        document.getElementById("echarts3"),
        null,
      );
      // 绘制图表
      echarts3.setOption(this.echarts3Data);
      window.addEventListener("resize", echarts3.resize);
    },

    // 订单数据
    getOrderData() {
      var echarts4 = echarts.init(
        document.getElementById("echarts4"),
        null,
      );
      // 绘制图表
      echarts4.setOption(this.echarts4Data);
      window.addEventListener("resize", echarts4.resize);
    },

    // 考试数据
    getExamData() {
      var echarts5 = echarts.init(
        document.getElementById("echarts5"),
        null,
      );
      // 绘制图表
      echarts5.setOption(this.echarts5Data);
      window.addEventListener("resize", echarts5.resize);
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      // 用户认证
      this.getUserIdentification();
      // 注册情况
      this.getRegister();
      // 不同时间段数据统计
      this.getPeopleNum();
      // 订单数据
      this.getOrderData();
      // 考试数据
      this.getExamData();
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {box-sizing: border-box;}
.ant-layout-content .window {
  background-color: transparent !important;
}
.DataAnalyse {

  .box {
    padding: 22px 30px 28px 16px;
    margin-bottom: 16px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1000);
    border-radius: 8px;
    background-color: #ffffff;
    .box_title {
      display: flex;
      align-items: center;
      .title_icon {
        width: 19px;
      }
      .title_txt {
        font-size: 22px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 26px;
        margin-left: 4px;
      }
    }
    .box_input {
      margin-top: 26px;
      .ant-calendar-picker {
        width: 226px;
      }
    }
    .box_info_title {
      .line {
        display: inline-block;
        width: 2px;
        height: 12px;
        background: #3681F0;
      }
      .title_txt {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        margin-left: 4px;
      }
      .title_info {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        margin-left: 4px;
      }
    }
    .box_line {
      width: 100%;
      height: 1px;
      background-color: #E5E5E5;
      margin-top: 30px;
    }
    .box_echart {
      width: 100%;
      margin-top: 25px;
      .echart_main {
        margin-top: 28px;
      }
    }
  }

  // 数据总览
  .box1 {
    position: relative;
    .box_term {
      position: absolute;
      display: flex;
      top: 25px;
      right: 30px;
      .term_item {
        width: 64px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        background: #E5F0FF;
        border-radius: 3px 3px 3px 3px;
        margin-left: 40px;
        cursor: pointer;
        &.pick {
          color: #FFFFFF;
          background: #60A0FF;
          box-shadow: inset 0px 4px 4px 0px rgba(0,0,0,0.1500);
        }
      }
    }
    .box_con {
      display: flex;
      justify-content: space-between;
      background: #F6F8FC;
      margin-top: 20px;
      padding: 20px 28px;
      .con_item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 23%;
        height: 123px;
        border-radius: 7px;
        padding: 24px 16px 20px 16px;
        &.item1 {
          background: linear-gradient(135deg, #7F7EFE 0%, #6FA7FE 100%);
        }
        &.item2 {
          background: linear-gradient(135deg, #FF5B5E 0%, #FF7092 100%);
        }
        &.item3 {
          background: linear-gradient(135deg, #FF895E 0%, #FFAF62 100%);
        }
        &.item4 {
          background: linear-gradient(135deg, #A459FB 0%, #BC5EFF 100%);
        }
        .item_top {
          .top_icon {
            width: 16px;
          }
          .top_txt {
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 19px;
            margin-left: 6px;
          }
        }
        .item_bottom {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .bottom_num {
            .num_txt {
              font-size: 32px;
              font-family: DIN Alternate-Bold, DIN Alternate;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 38px;
            }
            .num_info {
              font-size: 14px;
              font-family: PingFang SC-Light, PingFang SC;
              font-weight: 300;
              color: #FFFFFF;
              line-height: 16px;
            }
          }
          .bottom_up {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            .up_con {
              font-size: 16px;
            }
            .up_icon {
              width: 10px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }

  // 用户数据
  .box2 {
    .box_echart1 {
      display: flex;
      .echart_item {
        width: 50%;
      }
      .echart_main {
        width: 100%;
        height: 520px;
      }
    }
  }

  // 订单数据
  .box3 {
    .box_echart {
      display: flex;
      .echart_item1 {
        width: 60%;
      }
      .echart_item2 {
        width: 40%;
        .box_con {
          margin-top: 30px;
          .con_title {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            background: #F7F7F9;
            margin-bottom: 16px;
            .title_txt {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 16px;
              &.title1 {
                min-width: 50px;
                text-align: center;
              }
              &.title3 {
                min-width: 80px;
                text-align: center;
              }
            }
          }
          .con_swiper {
            // padding-top: 16px;
            padding-bottom: 2px;
            /deep/.swiper_slide {
              // width: 100% !important;
            }
            /deep/.swiper-wrapper {
              padding-bottom: 30px;
            }
            /deep/.swiper-pagination-bullet {
              width: 10px;
              height: 10px;
              margin: 0 6px;
            }
            .slide_wrap {
              box-shadow: 0px 4px 4px 0px rgba(93,131,167,0.1500);
              border-radius: 0px 0px 10px 10px;
              padding-bottom: 1px;
              margin: 10px;
            }
            .swiper_item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 21px;
              padding: 0 8px;
              div {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 16px;
              }
              .item_index {
                min-width: 50px;
                text-align: center;
              }
              .item_num {
                min-width: 80px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  // 学习数据
  .box4 {
    .box_con {
      width: 100%;
      background: #F6F8FC;
      padding: 28px 128px 28px 35px;
      margin-top: 20px;
      .con_item {
        display: flex;
        margin-top: 28px;
        &:nth-child(1) {
          margin-top: 0;
        }
        .item_title {
          width: 105px;
          text-align: right;
        }
        .item_line {
          position: relative;
          width: calc(100% - 145px);
          height: 16px;
          background: rgba(158, 187, 214, .2);
          border-radius: 18px;
          margin-left: 24px;
          margin-right: 16px;
          .item_progress {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            background: #60A0FF;
            box-shadow: 5px 0px 5px 0px rgba(30,151,180,0.1500);
            border-radius: 18px;
            border: 1px solid #FFFFFF;
            z-index: 10;
          }
        }
        .item_num {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .echart_main {
      position: relative;
      .main_label {
        display: flex;
        position: absolute;
        right: 75px;
        box-shadow: 0px 4px 4px 0px rgba(93,131,167,0.0800);
        border-radius: 10px;
        .label_title {
          background: #F7F7F9;
          border-radius: 4px;
          padding: 8px 20px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
        }
        .label_con {
          display: flex;
          padding: 8px 16px;
          .con_item {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            &::after {
              display: inline-block;
              content: '';
              width: 1px;
              height: 14px;
              background: #F2F2F2;
              margin-left: 12px;
              margin-right: 12px;
            }
            &:nth-last-child(1) {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

}

</style>
